import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from 'html-react-parser'

const Aboutus = () => {
  const [show, setShow] = useState();
  const [show4, setShow4] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // package
  const [show1, setShow1] = useState();
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // integrating post method service
  const formdata = new FormData();
  const [ServiceImage, setServiceImage] = useState("");
  const [ServiceText, setServiceText] = useState("");
  const [PackagePrice, setPackagePrice] = useState("");
  const [PackageDesc, setPackageDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setPackageDesc(data);
  };

  const AddServiceDetais = async () => {
    formdata.append("ServiceImage", ServiceImage);
    formdata.append("ServiceText", ServiceText);
    formdata.append("PackagePrice", PackagePrice);
    formdata.append("PackageDesc", PackageDesc);

    try {
      if (!ServiceImage) {
        return alert("Please add Image");
      }
      if (!ServiceText) {
        return alert("Please Choose Package Name");
      }
      if (!PackagePrice) {
        return alert("Please add Package price");
      }
      if (!PackageDesc) {
        return alert("Please add Package benefits");
      }

      const config = {
        url: "/admin/service",
        method: "post",
        baseURL: "https://parntertripgraam.shop/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddServices();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddServices, setAddServices] = useState([]);
  const getAddServices = async () => {
    try {
      let res = await axios.get("https://parntertripgraam.shop/api/admin/getservice");
      if (res.status === 200) {
        setAddServices(res.data.getservice);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("service", AddServices);
  //delete method
  const [Data, setData] = useState("");
  const DeleteServices = async () => {
    try {
      const config = {
        url: "admin/Deleteservice/" + Data,
        method: "delete",
        baseURL: "https://parntertripgraam.shop/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddServices();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
    setServiceText(item?.ServiceText);
    setPackagePrice(item.PackagePrice);
    setPackageDesc(item.PackageDesc);
  };

  const editService = async (e) => {
    e.preventDefault();
    formdata.append("ServiceImage", ServiceImage);
    formdata.append("ServiceText", ServiceText);
    formdata.append("PackagePrice", PackagePrice);
    formdata.append("PackageDesc", PackageDesc);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editservice",
        method: "put",
        baseURL: "https://parntertripgraam.shop/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getAddServices();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddServices();
  }, []);
  console.log(AddServices);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 3;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddServices.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddServices.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  // integrating post method service
  const [PackageName, setPackageName] = useState("");

  const AddPackageDetais = async () => {
    formdata.append("PackageName", PackageName);
    try {
      const config = {
        url: "/admin/package",
        method: "post",
        baseURL: "https://parntertripgraam.shop/api",
        header: { "content-type": "application/json" },
        data: {
          PackageName: PackageName,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddPackage();
        handleClose1();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get  method
  const [newData, setNewdata] = useState({});
  const [AddPackage, setAddPackage] = useState([]);
  console.log("owperjpowje", AddPackage);
  const getAddPackage = async () => {
    try {
      let res = await axios.get("https://parntertripgraam.shop/api/admin/getpackage");
      if (res.status === 200) {
        setAddPackage(res.data.getpackage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Datass, setDatass] = useState("");
  const DeletePackage = async () => {
    try {
      const config = {
        url: "admin/Deletepackage/" + Datass,
        method: "delete",
        baseURL: "https://parntertripgraam.shop/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddPackage();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data3, setData3] = useState("");
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData3(item);
    setPackageName(item?.PackageName);
  };

  const EditPackage = async (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("PackageName", PackageName);
    formdata.append("id", Data3?._id);
    console.log(Data3?._id, "WORFEROUERU9G9ER");

    try {
      const config = {
        url: "admin/editpackage/",
        method: "put",
        baseURL: "https://parntertripgraam.shop/api/",
        headers: { "Content-Type": "application/json" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Updated");
          handleClose5();
          getAddPackage();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddPackage();
  }, []);
  console.log(AddPackage);

  // pagination
  const [currenpage1, setCurrentpage1] = useState(1);
  const recordsperpage1 = 200;
  const lastIndex1 = currenpage1 * recordsperpage1;
  const firstIndex1 = lastIndex1 - recordsperpage1;
  const records1 = AddPackage.slice(firstIndex1, lastIndex1);
  const npages1 = Math.ceil(AddPackage.length / recordsperpage1);
  const numbers1 = [...Array(npages + 1).keys()].slice(1);

  function changePage1(id) {
    setCurrentpage1(id);
  }

  function prevpage1() {
    if (currenpage1 !== firstIndex1) {
      setCurrentpage1(currenpage1 - 1);
    }
  }

  function nextpage1() {
    if (currenpage1 !== lastIndex1) {
      setCurrentpage1(currenpage1 + 1);
    }
  }

  // package end

  // condition variable
  const [serivce, setserivce] = useState(false);
  const [category, setcategory] = useState(true);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex gap-3">
          <button
            className="admin-add-btn"
            onClick={() => {
              setserivce(true);
              setcategory(false);
            }}
          >
            Add Packages
          </button>
          <button
            className="admin-add-btn"
            onClick={() => {
              setserivce(false);
              setcategory(true);
            }}
          >
            Add Services
          </button>
        </div>

        {serivce ? (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Our Package</h2>
              <div className="d-flex gap-3">
                <button className="admin-add-btn" onClick={handleShow1}>
                  Add Package
                </button>
              </div>
            </div>

            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Package Name</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {records1?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1 + firstIndex}</td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.PackageName}
                        </td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className="text-success"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  handleShow5();
                                  setData3(item);
                                  setNewdata(item);
                                }}
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  setDatass(item?._id);
                                  handleShow2();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <div>
              <nav>
                <ul className="pagination">
                  <li className="not-allow">
                    <span>
                      <li className="next-prev">
                        <a
                          onClick={() => {
                            prevpage1();
                          }}
                        >
                          &lt;
                        </a>{" "}
                      </li>
                    </span>
                  </li>
                  {numbers1?.map((n, i) => {
                    return (
                      <li className="active-next" key={i}>
                        <a
                          href="#"
                          className="inactive"
                          onClick={() => changePage1(n)}
                        >
                          {n}
                        </a>
                      </li>
                    );
                  })}

                  <li className="not-allow">
                    <span>
                      <li
                        className="next-prev"
                        onClick={() => {
                          nextpage1();
                        }}
                      >
                        &gt;{" "}
                      </li>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        ) : (
          <></>
        )}

        {category ? (
          <>
            {" "}
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="header-c ">Our Services</h2>
                <div className="d-flex gap-3">
                  <button className="admin-add-btn" onClick={handleShow}>
                    Add Services
                  </button>
                </div>
              </div>

              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Package Name</th>
                    <th>Package Price</th>
                    <th>Benefits</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {records?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1 + firstIndex}</td>
                        <td>
                          <Image
                            src={`https://parntertripgraam.shop/Homeservices/${item?.ServiceImage}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.ServiceText}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.PackagePrice}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                        {parse(`<div>${item.PackageDesc}</div>`)}
                        </td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className="text-success"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => handleShow3(item)}
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  handleShow4();
                                  setData(item?._id);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <div>
              <nav>
                <ul className="pagination">
                  <li className="not-allow">
                    <span>
                      <li className="next-prev">
                        <a
                          onClick={() => {
                            prevpage();
                          }}
                        >
                          &lt;
                        </a>{" "}
                      </li>
                    </span>
                  </li>
                  {numbers1?.map((n, i) => {
                    return (
                      <li className="active-next" key={i}>
                        <a
                          href="#"
                          className="inactive"
                          onClick={() => changePage(n)}
                        >
                          {n}
                        </a>
                      </li>
                    );
                  })}

                  <li className="not-allow">
                    <span>
                      <li
                        className="next-prev"
                        onClick={() => {
                          nextpage();
                        }}
                      >
                        &gt;{" "}
                      </li>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Add service modal */}
        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>Add Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Package Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setServiceImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Choose Package Name</label>
                <select
                  className="form-control"
                  onChange={(e) => setServiceText(e.target.value)}
                >
                  <option value="">Select Package</option>
                  {AddPackage?.map((item, i) => {
                    return (
                      <option value={item.PackageName}>
                        {item.PackageName}
                      </option>
                    );
                  })}
                </select>
               
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Package Price</label>
                <input
                  type="text"
                  name=""
                  className="vi_0"
                  onChange={(e) => setPackagePrice(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Package Benefits</label>
                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddServiceDetais}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit service modal */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>Edit Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Package Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setServiceImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Choose Package Name</label>
                <select
                  className="form-control"
                  onChange={(e) => setServiceText(e.target.value)}
                  value={ServiceText}
                >
                  <option value="">Select Package</option>
                  {AddPackage?.map((item, i) => {
                    return (
                      <option value={item.PackageName}>
                        {item.PackageName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Package Price</label>
                <input
                  type="number"
                  name=""
                  id=""
                  className="vi_0"
                  value={PackagePrice}
                  onChange={(e) => setPackagePrice(e.target.value)}
                />
              </div>
            </div>
            
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Package Benefits</label>
                
                <CKEditor editor={ClassicEditor} 
                data={PackageDesc}
                onChange={handleChange} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose3}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={editService}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delet service modal  */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteServices}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* Add package modal */}
      <Modal show={show1} onHide={handleClose1} style={{ zIndex: "99999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Add Packages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Package Name</label>

              <input
                type="text"
                className="vi_0"
                placeholder="Enter Service Title"
                onChange={(e) => setPackageName(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose1}
            >
              Close
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={AddPackageDetais}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit package modal */}
      <Modal
        show={show5}
        onHide={handleClose5}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Edit Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Package Name</label>
              <input
                type="text"
                className="vi_0"
                value={PackageName}
                placeholder={newData?.PackageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="modal-close-btn" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="" className="modal-add-btn" onClick={EditPackage}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delet package modal  */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="fs-4" style={{ color: "red" }}>
                Are you sure?
                <br /> you want to delete this data?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="modal-close-btn" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="" className="modal-add-btn" onClick={DeletePackage}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Aboutus;
