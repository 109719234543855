import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { IoMdEye } from "react-icons/io";

const Places = () => {
  const [show6, setShow6] = useState();
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [shows, setShows] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [View, setView] = useState({});

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setWOverviewDesc(data);
  };
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setWInctutionDesc(data);
  };
  const handleChange3 = (e, editor) => {
    const data = editor.getData();
    setWExctutionDesc(data);
  };

  const handleChange5 = (e, editor) => {
    const data = editor.getData();
    setPlaceDescription(data);
  };

  const handleChange6 = (e, editor) => {
    const data = editor.getData();
    setWayDescription(data);
  };
  console.log("data========>", View);
  // integrating post method
  const [PlaceBannerImage, setPlaceBannerImage] = useState("");
  const [PlaceBannerName, setPlaceBannerName] = useState("");
  const [PlaceImage, setPlaceImage] = useState("");
  const [PlaceName, setPlaceName] = useState("");
  const [PlaceImage1, setPlaceImage1] = useState("");
  const [PlaceImage2, setPlaceImage2] = useState("");
  const [PlaceImage3, setPlaceImage3] = useState("");
  const [PlaceImage4, setPlaceImage4] = useState("");
  const [ActualPrice, setActualPrice] = useState("");
  const [OfferPrice, setOfferPrice] = useState("");
  const [PlaceDescription, setPlaceDescription] = useState("");
  const [WayDescription, setWayDescription] = useState("");
  const [WOverview, setWOverview] = useState("");
  const [WOverviewDesc, setWOverviewDesc] = useState("");
  const [WInctution, setWInctution] = useState("");
  const [WInctutionDesc, setWInctutionDesc] = useState("");
  const [WExctution, setWExctution] = useState("");
  const [WExctutionDesc, setWExctutionDesc] = useState("");
  const [PlaceCat, setPlaceCat] = useState("");

  const Addplace = async () => {
    const formdata = new FormData();
    formdata.append("PlaceBannerImage", PlaceBannerImage);
    formdata.append("PlaceBannerName", PlaceBannerName);
    formdata.append("PlaceImage", PlaceImage);
    formdata.append("PlaceImage1", PlaceImage1);
    formdata.append("PlaceImage2", PlaceImage2);
    formdata.append("PlaceImage3", PlaceImage3);
    formdata.append("PlaceImage4", PlaceImage4);
    formdata.append("PlaceName", PlaceName);
    formdata.append("PlaceDescription", PlaceDescription);
    formdata.append("WayDescription", WayDescription);
    formdata.append("OfferPrice", OfferPrice);
    formdata.append("ActualPrice", ActualPrice);
    formdata.append("WOverview", WOverview);
    formdata.append("WOverviewDesc", WOverviewDesc);
    formdata.append("WInctution", WInctution);
    formdata.append("WInctutionDesc", WInctutionDesc);
    formdata.append("WExctution", WExctution);
    formdata.append("WExctutionDesc", WExctutionDesc);
    formdata.append("PlaceCat", PlaceCat);

    try {
      if (!PlaceCat) {
        return alert("please select place name");
      }
      if (!PlaceBannerImage) {
        return alert("Please add Image");
      }
      if (!PlaceBannerName) {
        return alert("Please add text");
      }
      if (!PlaceImage) {
        return alert("Please add Image");
      }
      if (!PlaceImage1) {
        return alert("Please add Image");
      }
      if (!PlaceImage2) {
        return alert("Please add Image");
      }
      if (!PlaceImage3) {
        return alert("Please add Image");
      }
      if (!PlaceImage4) {
        return alert("Please add Image");
      }
      if (!PlaceName) {
        return alert("Please add text");
      }
      if (!PlaceDescription) {
        return alert("Please add Image");
      }
      if (!WayDescription) {
        return alert("Please add Image");
      }
      if (!ActualPrice) {
        return alert("Please add actual price");
      }
      if (!OfferPrice) {
        return alert("Please add offerprice");
      }
      if (!WOverview) {
        return alert("Please add text");
      }
      if (!WOverviewDesc) {
        return alert("Please add Image");
      }
      if (!WInctution) {
        return alert("Please add text");
      }
      if (!WInctutionDesc) {
        return alert("Please add Image");
      }
      if (!WExctution) {
        return alert("Please add text");
      }
      if (!WExctutionDesc) {
        return alert("Please add text");
      }

      const config = {
        url: "/admin/wplace",
        method: "post",
        baseURL: "https://parntertripgraam.shop/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddWayanadPlaces();
        handleCloses();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddWayanadPlaces, setAddWayanadPlaces] = useState([]);
  const getAddWayanadPlaces = async () => {
    try {
      let res = await axios.get("https://parntertripgraam.shop/api/admin/getwplace");
      if (res.status === 200) {
        setAddWayanadPlaces(res.data.getwplace);
        setNoChangeData1(res.data.getwplace);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Datas, setDatas] = useState("");
  const Deleteplace = async () => {
    try {
      const config = {
        url: "admin/Deletewplace/" + Datas,
        method: "delete",
        baseURL: "https://parntertripgraam.shop/api/",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddWayanadPlaces();
          handleClose6();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data11, setData11] = useState("");
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData11(item);
    setPlaceName(item?.PlaceName);
  };
  const Editplaces = async (e) => {
    e.preventDefault();
    const formdataedit = new FormData();
    formdataedit.append("PlaceBannerImage", PlaceBannerImage);
    formdataedit.append("PlaceBannerName", PlaceBannerName);
    formdataedit.append("PlaceImage", PlaceImage);
    formdataedit.append("PlaceImage1", PlaceImage1);
    formdataedit.append("PlaceImage2", PlaceImage2);
    formdataedit.append("PlaceImage3", PlaceImage3);
    formdataedit.append("PlaceImage4", PlaceImage4);
    formdataedit.append("PlaceName", PlaceName);
    formdataedit.append("PlaceDescription", PlaceDescription);
    formdataedit.append("WayDescription", WayDescription);
    formdataedit.append("OfferPrice", OfferPrice);
    formdataedit.append("ActualPrice", ActualPrice);
    formdataedit.append("PlaceCat", PlaceCat);
    formdataedit.append("WOverview", WOverview);
    formdataedit.append("WOverviewDesc", WOverviewDesc);
    formdataedit.append("WInctution", WInctution);
    formdataedit.append("WInctutionDesc", WInctutionDesc);
    formdataedit.append("WExctution", WExctution);
    formdataedit.append("WExctutionDesc", WExctutionDesc);
    formdataedit.append("id", Data11?._id);

    try {
      const config = {
        url: "admin/editwplace",
        method: "put",
        baseURL: "https://parntertripgraam.shop/api/",
        headers: { "content-type": "multipart/form-data" },
        data: formdataedit,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose5();
          getAddWayanadPlaces();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddWayanadPlaces();
  }, []);
  console.log(AddWayanadPlaces);

  // pagination
  const [currenpage1, setCurrentpage1] = useState(1);
  const recordsperpage1 = 5;
  const lastIndex1 = currenpage1 * recordsperpage1;
  const firstIndex1 = lastIndex1 - recordsperpage1;
  const records1 = AddWayanadPlaces.slice(firstIndex1, lastIndex1);
  const npages1 = Math.ceil(AddWayanadPlaces.length / recordsperpage1);
  const numbers1 = [...Array(npages1 + 1).keys()].slice(1);

  function changePage1(id) {
    setCurrentpage1(id);
  }

  function prevpage1() {
    if (currenpage1 !== firstIndex1) {
      setCurrentpage1(currenpage1 - 1);
    }
  }

  function nextpage1() {
    if (currenpage1 !== lastIndex1) {
      setCurrentpage1(currenpage1 + 1);
    }
  }

  //integrating get  method
  const [newData, setNewdata] = useState({});
  const [AddPlaces, setAddPlaces] = useState([]);

  const getAddPlaces = async () => {
    try {
      let res = await axios.get("https://parntertripgraam.shop/api/admin/getplace");
      if (res.status === 200) {
        setAddPlaces(res.data.getplace);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddPlaces();
  }, []);
  console.log(AddPlaces);

  // Search filter
  const [nochangedata, setNoChangeData1] = useState([]);
  const [searchH, setSearchH] = useState("");

  const handleFilterH = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchH(searchTerm);
    if (searchTerm !== "") {
      const filteredData = nochangedata.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm)
        )
      );
      setAddWayanadPlaces(filteredData);
    } else {
      setAddWayanadPlaces(nochangedata);
    }
  };

  return (
    <div>
      <div className="customerhead p-2">

        <div className="col-lg-4 d-flex justify-content-center mt-3">
          <div class="input-group ">
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={handleFilterH}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c "> Places</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShows}>
              Add Places
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Place Name</th>
                <th>Banner Image</th>
                <th>Banner Title</th>
                <th>Place Images </th>
                <th>Place Name</th>
                <th>Description</th>
                <th>Overview Title</th>
                <th>Overview Description</th>
                <th>Inclusion Title</th>
                <th>Inclusion Description</th>
                <th>Exclusion Title</th>
                <th>Exclusion Description</th>
                <th>Way to Reach</th>
                <th>Actual Price</th>
                <th>Offer Price</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {records1?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1 + firstIndex1}</td>
                    <td style={{ paddingTop: "20px" }}>{item.PlaceCat}</td>
                    <td>
                      <img
                        src={`https://parntertripgraam.shop/Places/${item?.PlaceBannerImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>

                    <td style={{ paddingTop: "20px" }}>
                      {item.PlaceBannerName}
                    </td>

                    <td style={{ paddingTop: "20px" }}>
                      <IoMdEye
                        style={{ fontSize: "22" }}
                        onClick={() => {
                          handleShow1();
                          setView(item);
                        }}
                      />
                    </td>

                    <td style={{ paddingTop: "20px" }}>{item.PlaceName}</td>
                    <td style={{ paddingTop: "20px" }}>
                      <div className="scroller">
                      {parse(`<div>${item.PlaceDescription}</div>`)}
                      </div>
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item.WOverview}</td>
                    <td style={{ paddingTop: "20px" }}>
                    <div className="scroller">
                      {parse(`<div>${item.WOverviewDesc}</div>`)}
                      </div>
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item.WInctution}</td>
                    <td style={{ paddingTop: "20px" }}>
                    <div className="scroller">
                      {parse(`<div>${item.WInctutionDesc}</div>`)}
                      </div>
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item.WExctution}</td>
                    <td style={{ paddingTop: "20px" }}>
                    <div className="scroller">
                      {parse(`<div>${item.WExctutionDesc}</div>`)}
                      </div>
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                    <div className="scroller">
                      {parse(`<div>${item.WayDescription}</div>`)}
                      </div>
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item.ActualPrice}</td>
                    <td style={{ paddingTop: "20px" }}>{item.OfferPrice}</td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow5(item);
                              setData11(item);
                              setNewdata(item);

                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow6();
                              setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal */}
        <Modal show={shows} onHide={handleCloses} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Add Place Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="do-sear mt-2">
              <label>Choose Place Name</label>
                <select
                  className="form-control"
                  onChange={(e) => setPlaceCat(e.target.value)}
                >
                  <option value="">Select Place Name</option>
                  {AddPlaces?.map((item, i) => {
                    return (
                      <option value={item.PlaceName}>{item.PlaceName}</option>
                    );
                  })}
                </select>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label> Place Banner Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceBannerImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Banner Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Banner Title"
                    onChange={(e) => setPlaceBannerName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage1(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage2(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage3(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage4(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Name</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Place Name"
                    onChange={(e) => setPlaceName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange5} />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Overview Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Overview Title"
                    onChange={(e) => setWOverview(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Overview Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange1} />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Inclusion Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Incusion Title"
                    onChange={(e) => setWInctution(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Inclusion Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange2} />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Exclusion Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Exclusion Title"
                    onChange={(e) => setWExctution(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Exclusion Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange3} />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>How to Reach</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange6} />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Actual Price</label>
                  <input
                    type="number"
                    className="vi_0"
                    placeholder="Enter Actual Price"
                    onChange={(e) => setActualPrice(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Offer Price</label>
                  <input
                    type="number"
                    className="vi_0"
                    placeholder="Enter Offer Price"
                    onChange={(e) => setOfferPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleCloses}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={Addplace}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Place Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="do-sear mt-2">
              <label>Choose Place Name</label>
                <select
                  className="form-control"
                  onChange={(e) => setPlaceCat(e.target.value)}
                >
                  <option value="">Select Place Name</option>
                  {AddPlaces?.map((item, i) => {
                    return (
                      <option value={item.PlaceName}>{item.PlaceName}</option>
                    );
                  })}
                </select>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label> Place Banner Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceBannerImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Banner Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={PlaceBannerName}
                    placeholder={newData?.PlaceBannerName }
                    onChange={(e) => setPlaceBannerName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage1(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage2(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage3(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setPlaceImage4(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Place Name</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={PlaceName}
                    onChange={(e) => setPlaceName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={handleChange5}
                    data={PlaceDescription}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Overview Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={WOverview}
                    placeholder="Enter Overview Title"
                    onChange={(e) => setWOverview(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Overview Description</label>
                  <CKEditor editor={ClassicEditor}
                   onChange={handleChange1} 
                   data={WOverviewDesc}
/>
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Inclusion Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={WInctution}
                    placeholder="Enter Incusion Title"
                    onChange={(e) => setWInctution(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Inclusion Description</label>
                  <CKEditor editor={ClassicEditor} 
                  onChange={handleChange2} 
                  data={WInctutionDesc}/>
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Exclusion Title</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={WExctution}
                    placeholder="Enter Exclusion Title"
                    onChange={(e) => setWExctution(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Exclusion Description</label>
                  <CKEditor editor={ClassicEditor}
                   onChange={handleChange3} 
                   data={WExctutionDesc}/>
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>How to Reach</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={handleChange6}
                    data={WayDescription}
                  />
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Actual Price</label>
                    <input
                      type="number"
                      className="vi_0"
                      placeholder="Enter Actual Price"
                      onChange={(e) => setActualPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Offer Price</label>
                    <input
                      type="number"
                      className="vi_0"
                      placeholder="Enter Offer Price"
                      onChange={(e) => setOfferPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={Editplaces}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delet modal  */}
        <Modal
          show={show6}
          onHide={handleClose6}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose6}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={Deleteplace}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Slider Image modal  */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              About Showcase Images
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://parntertripgraam.shop/Places/${View?.PlaceImage}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://parntertripgraam.shop/Places/${View?.PlaceImage1}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://parntertripgraam.shop/Places/${View?.PlaceImage2}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://parntertripgraam.shop/Places/${View?.PlaceImage3}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://parntertripgraam.shop/Places/${View?.PlaceImage4}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose1}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div>
          <nav>
            <ul className="pagination">
              <li className="not-allow">
                <span>
                  <li className="next-prev">
                    <a
                      onClick={() => {
                        prevpage1();
                      }}
                    >
                      &lt;
                    </a>{" "}
                  </li>
                </span>
              </li>
              {numbers1?.map((n, i) => {
                return (
                  <li className="active-next" key={i}>
                    <a
                      href="#"
                      className="inactive"
                      onClick={() => changePage1(n)}
                    >
                      {n}
                    </a>
                  </li>
                );
              })}

              <li className="not-allow">
                <span>
                  <li
                    className="next-prev"
                    onClick={() => {
                      nextpage1();
                    }}
                  >
                    &gt;{" "}
                  </li>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Places;
